.contact-section {
    position: relative;
    overflow: hidden;
    width: 100vw;
    min-height: 100vh;
    background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
    color: white;
    padding: 80px 0;
    text-align: center;
  }
  
  .contact-section::before {
    content: "";
    position: absolute;
    top: -50px;
    left: -50px;
    width: 200%;
    height: 200%;
    background: radial-gradient(circle, rgba(255, 255, 255, 0.2) 0%, transparent 70%);
    animation: moveBackground 6s linear infinite;
  }
  
  @keyframes moveBackground {
    0% {
      transform: translate(-50px, -50px) rotate(0deg);
    }
    50% {
      transform: translate(50px, 50px) rotate(180deg);
    }
    100% {
      transform: translate(-50px, -50px) rotate(360deg);
    }
  }
  
  .animated-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 0;
  }
  
  .contact-section h2 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .contact-section a.btn-primary {
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 50px;
    background-color: #ff6b6b;
    border: none;
    transition: all 0.3s ease-in-out;
  }
  
  .contact-section a.btn-primary:hover {
    background-color: #ff4757;
    transform: scale(1.1);
  }
  
  .contact-section form {
    background: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    backdrop-filter: blur(5px);
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .contact-section form label {
    font-weight: bold;
  }
  
  .contact-section form .form-control {
    border-radius: 8px;
  }
  
  .contact-section form button {
    font-size: 1.2rem;
    padding: 10px 20px;
    width: 100%;
    border-radius: 50px;
    background-color: #27ae60;
    border: none;
    transition: all 0.3s ease-in-out;
  }
  
  .contact-section form button:hover {
    background-color: #218c54;
    transform: scale(1.05);
  }
  
  .form-control {
    color: white !important;
    background: transparent !important;
  }
  
  .form-control::placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
  }
  